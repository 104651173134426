import React, { Component } from 'react';

class Footer extends Component {


    render() {
        return (
            <div>
                <section class="footer-17">
                    <div class="footer17_sur py-5">
                        <div class="container py-lg-5 py-md-4">
                            <div class="footer17-top">
                                <div class="footer17-top-left1_sur">
                                    <h6>Contact Us</h6>
                                    <ul>
                                        <li><a href="mailto:support@createPro.com">hello@beebee.digital</a></li>
                                        <li><a href="tel:+94 (70) 597 4657">+94 70-597-4657</a></li>
                                        <li><a href="tel:+6281287276810">+62 812-8727-6810</a></li>
                                    </ul>
                                    <ul class="footers-17_social">
                                        <li><a href="https://www.facebook.com/beebeedigital" class="facebook" target="_blank"><span class="fa fa-facebook"></span></a></li>
                                        <li><a href="https://www.linkedin.com/in/beebee-solutions" class="linkedin" target="_blank"><span class="fa fa-linkedin"></span></a></li>
                                        <li><a href="https://www.instagram.com/beebeedigital/" class="instagram"><span class="fa fa-instagram" target="_blank"></span></a></li>
                                    </ul>
                                </div>
                                <div class="footer17-top-left2_sur">
                                    <h6>Products</h6>
                                    <ul>
                                        <li><a href="https://www.pacaranapp.com/">Pacaran App</a></li>
                                        <li><a href="#">Pigeez</a></li>
                                        <li><a href="https://devacademy.info/">Dev Academy</a></li>
                                    </ul>
                                </div>
                                <div class="footer17-top-left3_sur">
                                    <h6>Company</h6>
                                    <ul>
                                        {/* <li><a href="about.html">About Us</a></li>
                                        <li><a href="#team">Join our team</a></li> */}
                                        <li><a href="contact.html">Contact us</a></li>
                                        {/* <li><a href="#press">Press release</a></li> */}
                                        <li><a href="#careers">Careers</a></li>
                                    </ul>
                                </div>
                                {/* <div class="footer17-top-left4_sur">
                                    <h6>Services</h6>
                                    <ul>
                                        <li><a href="#services">Digital Marketing</a></li>
                                        <li><a href="#seo">SEO for Business</a></li>
                                        <li><a href="#writing">Content writing</a></li>
                                        <li><a href="#design">UI Design</a></li>
                                    </ul>
                                </div> */}
                                {/* <div class="footer17-top-left5_sur">
                                    <h6>Legal </h6>
                                    <ul>
                                        <li><a href="#url">Privacy policy</a></li>
                                        <li><a href="#url">Terms of use</a></li>
                                        <li><a href="#url">Return Policy</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <div class="copyright text-center">
                            <div class="container">
                                <p class="copy-footer-29">© 2021 BEEBEE. All rights reserved | Powerd by <a href="https://beebee.digital/"
                                    target="_blank">Beebee Digital PVT LTD</a></p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Footer;