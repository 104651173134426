import './App.css';
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from "./screens/Home";
import AndroidFundamental212 from "./screens/AndroidFundamental212";
import JavaFundamental211 from "./screens/JavaFundamental211";
import Courses from "./screens/Courses";
import Contact from "./screens/Contact";

class App extends Component {

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
