import React, { Component } from 'react';

class Header extends Component {
    render() {
        
        return (
            <div class="header-saas">
                <header className="w3l-header">
                    <header id="site-header" class="fixed-top">
                        <div class="container">
                            <nav class="navbar navbar-expand-lg stroke">
                                {/* <a class="navbar-brand" href="index.html">
                                    Beebee Digital
                                </a> */}

                                <a class="navbar-brand" href="/">
                                    <img src="/logo_horizontal.svg" alt="" title="Your logo"/>
                                </a>
                                <button class="navbar-toggler  collapsed bg-gradient" type="button" data-toggle="collapse"
                                    data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon fa icon-expand fa-bars"></span>
                                    <span class="navbar-toggler-icon fa icon-close fa-times"></span>
                                </button>

                                <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                                    <ul class="navbar-nav ml-auto">
                                        {/* <li class="nav-item dropdown active">
                                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown1" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Demos<span class="fa fa-angle-down"></span>
                                            </a>
                                            <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
                                                <a class="dropdown-item active" href="index.html">Agency</a>
                                                <a class="dropdown-item @@saas__active" href="saas.html">SaaS Subscription</a>
                                                <a class="dropdown-item @@web__active" href="web.html">Web Application</a>
                                            </div>
                                        </li> */}
                                        <li class={(sessionStorage.getItem('view') === 'contact') ? 'nav-item active' : 'nav-item' }>
                                            <a class="nav-link" href="/contact">Contact Us</a>
                                        </li>

                                        <li class="nav-item @@contact__active">
                                            <a class="nav-link" target='_blank' href="https://www.facebook.com/beebeedigital/jobs/">Careers</a>
                                        </li>

                                        <li>

                                        </li>
                                        {/* <li class="mx-3">
                                            <a href="#btn" class="btn btn-style btn-primary">Start a free trail</a>
                                        </li> */}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </header>
                </header>
            </div>
        );
    }
}

export default Header;