import React, { Component } from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';
import TopBanner from '../components/TopBanner';

class Home extends Component {
  state = {
    view: 'home'
  }

  componentWillMount() {
    sessionStorage.setItem('view','home');
  }

  render() {

    const { view } = this.state;

    return (
      <div>
        <Header view="home" />

        <section class="w3l-banner-agency" id="work">
          <div class="midd-w3 py-md-4">
            <div class="container py-lg-2">
              <div class="row">
                <div class="col-lg-6 banner-content align-self pr-lg-5">
                  <h5 class="title-small mb-2"> -LET'S SHIFT YOUR BUSINESS- </h5>
                  <h3 class="title-toobig">We blend your idea with technology</h3>
                  <p class="mt-4">We Design, Develop and Make Your Innovative Idea into a Reality while Allowing You to Focus on Growing Your Business.</p>
                  <a href="/contact" class="btn btn-style btn-primary mt-lg-5 mt-4 mr-2">Talk to an expert</a>
                </div>
                <div class="col-lg-6 text-center">
                  <div class="image-block position-relative">
                    <img src="/header_image.svg" alt="" class="img-fluid mt-lg-0 mt-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="w3l-bottom-grids-6 py-5" id="services">
          <div class="container py-lg-5 py-md-4 py-2">
            <div class="title-content text-center">
              <h6 class="title-small text-center"> -WE WORK HARD PLAY HARD- </h6>
              <h3 class="title-big mb-sm-5 mb-4 pb-sm-o pb-2 text-center">We are specialized in</h3>
            </div>
            <div class="grids-area-hny main-cont-wthree-fea row">
              <div class="col-lg-4 col-md-6 grids-feature">
                <div class="area-box">
                  <span class="fa fa-mobile"></span>
                  <h4><a href="#feature" class="title-head">Mobile Application Development</a></h4>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 grids-feature mt-md-0 mt-4">
                <div class="area-box">
                  <span class="fa fa-desktop"></span>
                  <h4><a href="#feature" class="title-head">Desktop / Web Application Development</a></h4>

                </div>
              </div>
              <div class="col-lg-4 col-md-6 grids-feature mt-lg-0 mt-4">
                <div class="area-box">
                  <span class="fa fa-pencil-square-o"></span>
                  <h4><a href="#feature" class="title-head">Product / Solutions Design and Development</a></h4>
                </div>
              </div>
            </div>
            <br />
            <div class="grids-area-hny main-cont-wthree-fea row">
              <div class="col-lg-4 col-md-6 grids-feature">
                <div class="area-box">
                  <span class="fa fa-users"></span>
                  <h4><a href="#feature" class="title-head">Resource Talent Outsourcing</a></h4>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 grids-feature mt-md-0 mt-4">
                <div class="area-box">
                  <span class="fa fa-photo"></span>
                  <h4><a href="#feature" class="title-head">UI / UX Design and Prototyping</a></h4>

                </div>
              </div>
              <div class="col-lg-4 col-md-6 grids-feature mt-lg-0 mt-4">
                <div class="area-box">
                  <span class="fa fa-facebook"></span>
                  <h4><a href="#feature" class="title-head">Social Media Marketing and Management</a></h4>
                </div>
              </div>
            </div>

          </div>
        </section>

        <section class="w3l-banner-agency" id="work">
          <div class="midd-w3 py-md-4">
            <div class="container py-lg-2">
              <div class="row">
              <div class="col-lg-6 text-center">
                  <div class="image-block position-relative">
                    <img src="assets/images/worldmap.svg" alt="" class="img-fluid mt-lg-0 mt-4" />
                  </div>
                </div>
                
                <div class="col-lg-6 banner-content align-self pr-lg-5">
                  <h5 class="title-small mb-2"> -Quality is costly but lack of quality is even more costly- </h5>
                  <h3 class="title-toobig">From Sri Lanka to the World</h3>
                  <p class="mt-4">We are happy to #announce that our Tech-Expert #bees creates impactful digital products together with our global customers. Now we are even closer to you with our global presence in #APAC.</p>
                  <a href="/contact" class="btn btn-style btn-primary mt-lg-5 mt-4 mr-2">Talk to an expert</a>
                </div>
                
              </div>
            </div>
          </div>
        </section>

        <section class="w3l-brands py-sm-5 py-4" id="brands">
          <div class="call-w3">
            <div class="container">
              <div class="title-content text-center">
                <h6 class="title-small text-center"> - WE Improvise, Adapt and Overcome - </h6>
                <h3 class="title-big mb-sm-5 mb-4 pb-sm-o pb-2 text-center">Our Partners and Clients</h3>
              </div>
              <div class="company-logos">
                <div class="row center">
                  {/* <div class="col-lg-2 col-md-3 col-sm-4 col-6">
                    <img src="assets/images/logo1.png" alt="" class="img-fluid" />
                  </div> */}
                  <div class="col-lg-2 col-md-3 col-sm-4 col-6">
                    <img src="assets/images/logo2.png" alt="" class="img-fluid" />
                  </div>
                  <div class="col-lg-2 col-md-3 col-sm-4 col-6 mt-sm-0 mt-4">
                    <img src="assets/images/logo3.png" alt="" class="img-fluid" />
                  </div>
                  <div class="col-lg-2 col-md-3 col-sm-4 col-6 mt-md-0 mt-4">
                    <img src="assets/images/logo4.png" alt="" class="img-fluid" />
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default Home;