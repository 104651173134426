import React, { Component } from 'react';
import Typical from 'react-typical'
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
const Carousel = require('react-responsive-carousel').Carousel;

class TopBanner extends Component {

    render() {
        return (
            <section className="w3l-main-banner">
                <div className="companies20-content">
                    <div className="companies-wrapper">
                        <div className="container">
                            <div className="banner-item">
                                <div className="banner-view">
                                    <div className="banner-info">

                                        <Typical
                                            steps={['Learn Anytime, Anywhere.', 1500,
                                                'Accelerate Your Future.', 1500,
                                                'Learn from Industry Experts.', 1500,
                                                'Easy Payment Methods.', 1500]}
                                            loop={Infinity}
                                            className="banner-text"
                                            wrapper="h3" />
                                        <p className="my-4 mb-sm-5">
                                            Best place to learn software development fundamentals and advance design patterns which is directly targeted to the industry.
                                        </p>
                                        <br />
                                        <a href="/courses"
                                            className="btn btn-primary theme-button mr-3" onClick={() => this.props.onClickCourses()}>Courses</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="companies20-content">
                    <div className="companies-wrapper">
                        <div className="container">
                            <Carousel 
                            showArrows={true} 
                            showThumbs={false}
                            onChange={() =>{}} 
                            onClickItem={() =>{}} 
                            onClickThumb={() =>{}}>
                                <div>
                                    <img src="assets/images/image_1.png" />
                                    <p className="legend">Legend 1</p>
                                </div>
                                <div>
                                    <img src="assets/2.jpeg" />
                                    <p className="legend">Legend 2</p>
                                </div>
                                <div>
                                    <img src="assets/3.jpeg" />
                                    <p className="legend">Legend 3</p>
                                </div>
                                <div>
                                    <img src="assets/4.jpeg" />
                                    <p className="legend">Legend 4</p>
                                </div>
                                <div>
                                    <img src="assets/5.jpeg" />
                                    <p className="legend">Legend 5</p>
                                </div>
                                <div>
                                    <img src="assets/6.jpeg" />
                                    <p className="legend">Legend 6</p>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

export default TopBanner;