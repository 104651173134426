import React, { Component } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';


class JavaFundamental211 extends Component {
    render() {
        return (
            <div>
                <Header  view="courses"/>

                <section className="w3l-skill-breadcrum">
                    <div className="breadcrum">
                        <div className="container">
                            <p><a href="/">Home</a> &nbsp; / <a href="/courses">Courses</a> / <a href="/java-fundamental-21-1">Java development fundamentals 21.1</a></p>
                        </div>
                    </div>
                </section>
                
                <section className="w3l-about1" id="about">
                    <div id="cwp23-block" className="py-5">
                        <div className="container py-lg-5">
                            <div className="row cwp23-content">
                                <div className="col-lg-6 cwp23-text">
                                   
                                    <div className="cwp23-title">
                                        <h3>Java development fundamentals 21.1</h3>
                                    </div>
                                    
                                    <div>
                                        <p>
                                            Java is a high-level programming language originally developed by Sun Microsystems and released in 1995.
                                            Java runs on a variety of platforms, such as Windows, Mac OS, and the various versions of UNIX.
                                            This tutorial gives a complete understanding of Java. 
                                            This reference will take you through simple and practical approaches while learning Java Programming language.
                                        </p>

                                        <br/>
                                        <br/>

                                        <b>Course content</b>

                                        <p className="container">
                                            <ul>
                                                <li>Introduction to java</li>
                                                <li>Object and Classes</li>
                                                <li>Constructors</li>
                                                <li>Basic Data-types</li>
                                                <li>Variable Types</li>
                                                <li>Modifier Types</li>
                                                <li>Loop Control</li>
                                                <li>Decision Making</li>
                                                <li>Numbers</li>
                                                <li>Characters</li>
                                                <li>Strings</li>
                                                <li>Methods</li>
                                                <li>OOP Concepts</li>
                                            </ul>
                                        </p>

                                        <br/>
                                        <br/>
                                        

                                        <b>Course Fee : LKR 10000</b><br/>
                                        <b>Course Duration : 1 Month (15 Hours)</b><br/>
                                        <b>Starting Date : 2021 March 04 - 7.30 PM</b>

                                        <br/>
                                        <br/>

                                        <a href="https://forms.gle/tht7UmpqpMjCgP5C6" className="btn btn-success btn-sm" target="_blank" >Enroll Now</a>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 mt-lg-0 mt-5 cwp23-img">
                                    
                                    <img src="assets/images/java_fundamental_21_1.png" className="img-fluid" alt="" />

                                    <br/>
                                    <br/>

                                    <div className="cwp23-text-cols">
                                        {/* <div className="column">
                                            <span className="fa fa-video-camera" aria-hidden="true"></span>
                                            <a href="services.html">Online clasess</a>
                                            <p>Learn from home. we will share all the sessions</p>
                                        </div>
                                        <div className="column">
                                            <span className="fa fa-graduation-cap" aria-hidden="true"></span>
                                            <a href="services.html">Trending Courses</a>
                                            <p>consectetur adipisicing elit, sed do eiusmod tempor </p>
                                        </div>
                                        <div className="column">
                                            <span className="fa fa-history" aria-hidden="true"></span>
                                            <a href="services.html">Large Library</a>
                                            <p>consectetur adipisicing elit, sed do eiusmod tempor </p>
                                        </div>
                                        <div className="column">
                                            <span className="fa fa-users" aria-hidden="true"></span>
                                            <a href="services.html">Expert Teachers</a>
                                            <p>consectetur adipisicing elit, sed do eiusmod tempor </p>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

export default JavaFundamental211;