import React, { Component } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';


class AndroidFundamental212 extends Component {
    render() {
        return (
            <div>
                <Header view="courses"/>

                <section className="w3l-skill-breadcrum">
                    <div className="breadcrum">
                        <div className="container">
                            <p><a href="/">Home</a> &nbsp; / <a href="/courses">Courses</a> / <a href="/android-fundamental-21-2">Android Mobile Application Developments 21.2</a></p>
                        </div>
                    </div>
                </section>
                
                <section class="w3l-about1" id="about">
                    <div id="cwp23-block" class="py-5">
                        <div class="container py-lg-5">
                            <div class="row cwp23-content">
                                <div class="col-lg-6 cwp23-text">
                                   
                                    <div class="cwp23-title">
                                        <h3>Android Mobile Application Developments</h3>
                                    </div>
                                    
                                    <div>
                                        <p>
                                            Android is an open source and Linux-based operating system for mobile devices such as smartphones and tablet computers.
                                            Android was developed by the Open Handset Alliance, led by Google, and other companies.
                                            This tutorial will teach you basic Android programming and will also take you through some advance concepts related to Android application development.
                                        </p>

                                        <br/>
                                        <br/>

                                        <b>Course content</b>

                                        <p className="container">
                                            <ul>
                                                <li>Programing language fundamentals.</li>
                                                <li>Data Types</li>
                                                <li>Variables</li>
                                                <li>Keywords</li>
                                                <li>Logical and Arithmetic Operators</li>
                                                <li>If else conditions</li>
                                                <li>Loops</li>
                                                <li>Mobile development fundamentals and concepts.</li>
                                                <li>Mobile development key components.</li>
                                                <li>Navigations.</li>
                                                <li>Data handling.</li>
                                                <li>UI/UX.</li>
                                                <li>Introduction to Android.</li>
                                                <li>Introduction to Android Studio.</li>
                                                <li>Usage of Android studio and key functionalities.</li>
                                                <li>Design and develop.</li>
                                                <li>Debug.</li>
                                                <li>Key components.</li>
                                                <li>Build , Signing and publish.</li>
                                                <li>Android key components.</li>
                                                <li>Services.</li>
                                                <li>Activities.</li>
                                                <li>Broadcast receivers.</li>
                                                <li>Content providers.</li>
                                                <li>How to develop a basic android application.</li>
                                                <li>Build custom UI screens.</li>
                                                <li>Interact with UI components.</li>
                                                <li>Working with List views.</li>
                                                <li>Use third party libraries.</li>
                                                <li>Introduction to firebase.</li>
                                                <li>Connect firebase with Android applications.</li>
                                                <li>How to use firebase authenticate.</li>
                                                <li>How to use firebase realtime database.</li>
                                                <li>How to use firebase storage.</li>
                                                <li>How to use firebase crashlytics.</li>
                                                <li>Prepare for google play store submission.</li>
                                                <li>Signing and publishing.</li>
                                            </ul>
                                        </p>

                                        <br/>
                                        <br/>
                                        

                                        <b>Course Fee : LKR 10000</b><br/>
                                        <b>Course Duration : 1 Month (15 Hours)</b><br/>
                                        <b>Starting Date : 2021 March 08 - 7.30 PM</b>

                                        <br/>
                                        <br/>

                                        <a href="https://forms.gle/JKgJqJxTEJpxvrry7" className="btn btn-success btn-sm" target="_blank" rel="noreferrer">Enroll Now</a>
                                    </div>
                                    
                                </div>
                                <div class="col-lg-6 mt-lg-0 mt-5 cwp23-img">
                                    
                                    <img src="assets/images/android_fundamental_21_1.png" class="img-fluid" alt="" />

                                    <br/>
                                    <br/>

                                    <div class="cwp23-text-cols">
                                        {/* <div class="column">
                                            <span class="fa fa-video-camera" aria-hidden="true"></span>
                                            <a href="services.html">Online clasess</a>
                                            <p>Learn from home. we will share all the sessions</p>
                                        </div>
                                        <div class="column">
                                            <span class="fa fa-graduation-cap" aria-hidden="true"></span>
                                            <a href="services.html">Trending Courses</a>
                                            <p>consectetur adipisicing elit, sed do eiusmod tempor </p>
                                        </div>
                                        <div class="column">
                                            <span class="fa fa-history" aria-hidden="true"></span>
                                            <a href="services.html">Large Library</a>
                                            <p>consectetur adipisicing elit, sed do eiusmod tempor </p>
                                        </div>
                                        <div class="column">
                                            <span class="fa fa-users" aria-hidden="true"></span>
                                            <a href="services.html">Expert Teachers</a>
                                            <p>consectetur adipisicing elit, sed do eiusmod tempor </p>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

export default AndroidFundamental212;