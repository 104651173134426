import React, { Component } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

class Contact extends Component {
    
    componentWillMount() {
        sessionStorage.setItem('view','contact');
    }
    render() {
        return (
            <div>
                <Header view="contact" />

                <section class="w3l-contact-2 py-5" id="contact">
                    <div class="container py-lg-4 py-md-3 py-2">
                        <div class="title-content text-center">
                            <h3 class="title-toobig">Contact us</h3>
                            <p class="mt-md-4 mt-3 mx-lg-5 px-lg-5">Bees are everywhere. Find the nearest bee.</p>
                        </div>
                        <div class="contact-grids d-grid mt-lg-5 mt-4 mx-lg-5">
                            <div class="contact-left">
                                <div class="cont-details">
                                    <div class="cont-top margin-up">
                                        <div class="cont-left text-center">
                                            <span class="fa fa-map-marker"></span>
                                        </div>
                                        <div class="cont-right">
                                            <h6>Visit Us</h6>
                                            <p><b>Sri Lanka</b></p>
                                            <p>#181 Sri Saranathissa Avenue Kahathuduwa Polgasowita</p>
                                            <a href="mailto:hello@beebee.digital">hello@beebee.digital</a>
                                            <br/><br/>
                                            <p><b>Singapore</b></p>
                                            <p>#16 Raffles Quay Singapore 048581</p>
                                            <a href="mailto:sg@beebee.digital">sg@beebee.digital</a>
                                            <br/><br/>
                                            <p><b>Indonesia</b></p>
                                            <p>Casa Grande Residence, Montana 23-01,Jl. Casablanca Raya, SouthJakarta</p>
                                            <a href="mailto:id@beebee.digital">id@beebee.digital</a>
                                        </div>
                                    </div>
                                    <div class="cont-top margin-up">
                                        <div class="cont-left text-center">
                                            <span class="fa fa-phone"></span>
                                        </div>
                                        <div class="cont-right">
                                            <h6>Call Us</h6>
                                            <p><a href="tel:+94 (70) 597 4657">+94 70-597-4657</a></p>
                                            <p><a href="tel:+6281287276810">+62 812-8727-6810</a></p>
                                        </div>
                                    </div>
                                    {/* <div class="cont-top margin-up">
                                        <div class="cont-left text-center">
                                            <span class="fa fa-envelope-o"></span>
                                        </div>
                                        <div class="cont-right">
                                            <h6>Email Us</h6>
                                            <p><a href="mailto:support@mail.com" class="mail">support@mail.com</a></p>
                                            <p><a href="mailto:contact@mail.com" class="mail">contact@mail.com</a></p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div class="contact-right">
                                <form action="" method="post" class="signin-form">
                                    <div class="input-grids">
                                        <input type="text" name="w3lName" id="w3lName" placeholder="Your Name*" class="contact-input"
                                            required="" />
                                        <input type="email" name="w3lSender" id="w3lSender" placeholder="Your Email*"
                                            class="contact-input" required="" />
                                        <input type="text" name="w3lSubect" id="w3lSubect" placeholder="Subject*" class="contact-input"
                                            required="" />
                                    </div>
                                    <div class="form-input">
                                        <textarea name="w3lMessage" id="w3lMessage" placeholder="Type your message here*"
                                            required=""></textarea>
                                    </div>
                                    <button class="btn btn-style btn-primary">Send Message</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        );
    }
}

export default Contact;