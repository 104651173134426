import React, { Component } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';


class Courses extends Component {
    render() {
        return (
            <div>
                <Header  view="courses"/>
                <section className="w3l-skill-breadcrum">
                    <div className="breadcrum">
                        <div className="container">
                            <p><a href="/">Home</a> &nbsp; / &nbsp; <a href="/courses">Courses</a></p>
                        </div>
                    </div>
                </section>

                <section className="w3l-courses">
                    <div className="blog py-5" id="courses">
                        <div className="container py-lg-5">
                            <div className="header-section text-center">
                                <h3 className="mb-2">Ongoing Courses</h3>
                            </div>

                            <div className="row mt-md-5 mt-4">
                                <div className="col-md-12 row">
                                    <div className="col-md-4">
                                        <div className="card">
                                            <div className="card-header p-0 position-relative">
                                                <a href="#url" className="zoom d-block">
                                                    <img className="card-img-bottom d-block"
                                                        src="assets/images/android_fundamental_21_1.png" alt="Card image cap" />
                                                </a>
                                            </div>
                                            <div className="card-body course-details">
                                                <p>LKR 10000</p>
                                                <a href="#url" className="course-desc"><b>Android Fundamentals 21.2</b></a>
                                            </div>
                                            <div className="card-footer course-price-view">
                                                <a className="btn btn-sm btn-primary" href="/android-fundamental-21-2">Enroll now</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="card">
                                            <div className="card-header p-0 position-relative">
                                                <a href="#url" className="zoom d-block">
                                                    <img className="card-img-bottom d-block"
                                                        src="assets/images/java_fundamental_21_1.png" alt="Card image cap" />
                                                </a>
                                            </div>
                                            <div className="card-body course-details">
                                                <p>LKR 10000</p>
                                                <a href="#url" className="course-desc"><b>Java Fundamentals 21.1</b></a>
                                            </div>
                                            <div className="card-footer course-price-view">
                                                <a className="btn btn-sm btn-primary" href="/java-fundamental-21-1">Enroll now</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        );
    }
}

export default Courses;